import { JWTResponse } from '@chilipiper/api-type-def/src/auth'
import { captureException } from '@sentry/react'
import { store } from '@chilipiper/utils'
import { authService } from './auth'
import { api } from './endpoint'
import { jwtStore } from './jwtStore'
import { JwtLogin, JwtService } from './types'

const ACCESS_TOKEN = 'accessToken'

export const { setAccessToken, getAccessToken, removeAccessToken, hasAccessToken } =
  jwtStore(ACCESS_TOKEN)

const refresh = async () => {
  const data = await authService.getRefreshJwtFromCookie()
  setAccessToken(data.accessToken)
  return data
}

const revokeRefreshToken = async () => {
  try {
    await authService.revokeRefreshToken()
  } catch (err) {
    captureException(err)
  }
}

const getInitialJWT = async (code?: JwtLogin) => {
  if (code && typeof code === 'string') {
    // get accessToken for localStorage and refreshToken for cookie after successful login
    const token = await authService.getJWTCodeAuth(code)
    setAccessToken(token.accessToken)
    return token
  } else {
    const token = await authService.getJWTCookieAuthLegacy()
    setAccessToken(token.accessToken)
    return token
  }
}

export const logoutWithoutRedirect = async () => {
  await revokeRefreshToken().catch(() => {
    /* ignore in case already revoked */
  })
  await authService.logout().catch(() => {
    /* ignore redirect */
  })
  removeAccessToken()
  store.remove('sessionId')
}
export const logout = async () => {
  await logoutWithoutRedirect()
  if (typeof window !== 'undefined') {
    window.location.href = api('/logout')
  }
}

export const removeXSession = () => authService.removeXSession()

export const jwtApiV1: JwtService<JWTResponse> = {
  login: getInitialJWT,
  refresh,
  getToken: getAccessToken,
  setToken: setAccessToken,
  logout,
  isAuthenticated: hasAccessToken,
  getSessionData: () => undefined,
}
