import { JwtService } from './types'
import { jwtApiV2 } from './jwtApiV2'
import { jwtApiV1 } from './jwtApiV1'

export const jwtApiV2WithV1Fallback: JwtService<string> = {
  refresh: async () => {
    try {
      const response = await jwtApiV2.refresh()
      return response['jwt-access']
    } catch (e) {
      return (await jwtApiV1.refresh()).accessToken
    }
  },
  getToken: () => jwtApiV2.getToken() || jwtApiV1.getToken(),
  setToken: () => {
    throw new Error('setToken is not supported')
  },
  getSessionData: () => {
    throw new Error('getSessionData is not supported')
  },
  login: async initialFetchTokenCode => {
    try {
      const response = await jwtApiV2.login()
      return response['jwt-access']
    } catch (err) {
      return (await jwtApiV1.login(initialFetchTokenCode)).accessToken
    }
  },
  isAuthenticated: () => jwtApiV2.isAuthenticated() || jwtApiV1.isAuthenticated(),
  logout: async () => {
    await jwtApiV2.logout().catch(() => {
      /* ignore in case not logged in with V2 */
    })
    await jwtApiV1.logout()
  },
}
