import { store } from '@chilipiper/utils'

export const jwtStore = (accessTokenKey: string) => {
  return {
    setAccessToken: (token: string) => {
      store.set(accessTokenKey, token)
    },
    getAccessToken: () => {
      return store.get(accessTokenKey)
    },
    hasAccessToken: () => {
      return store.has(accessTokenKey)
    },
    removeAccessToken: () => {
      store.remove(accessTokenKey)
    },
  }
}
